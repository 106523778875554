import React from "react";

export default () => {

    return (
        <div style={{ marginBottom: 10 }}>
            <h1 className="h1" style={{marginTop: 10 , marginBottom: 10}}>
                  Zendesk Coding Challenge - 2021
            </h1>
            <p style={{ fontSize: 14, lineHeight: 1.3, marginBottom: 50 }}>
                A simple app to View the Ticket Details
            </p>
        </div>
    );
};
